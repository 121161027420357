import React from 'react';
import { ApiKeyContext } from '.';
import { Route, Routes } from 'react-router-dom';
import AppRoutes from './AppRoutes';
// import { Error } from './pages/error';
import { theme } from './utils/theme';
import { ThemeProvider } from '@mui/material/styles';
import { getMetaData, getStripePublishableKey } from './utils/api';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import LanguageSelect from './components/UI/SingleSelect/LanguageSelect';

export const BrandContext = React.createContext();

const App = (props) => {
  const [brandDetails, setBrandDetails] = React.useState();
  const [publishableKey, setPublishableKey] = React.useState();
  const [fetchRequestSuccess, setFetchRequestSuccess] = React.useState(false);
  let apikey = React.useContext(ApiKeyContext);

  const fetchMetaData = React.useCallback(async () => {
    await getMetaData(apikey)
      .then((resp) => {
        setBrandDetails(resp?.data ?? {});
        // console.log('Brand Details: ', resp?.data);
        setFetchRequestSuccess(true);
      })
      .catch((error) => {
        console.log('Failed to load Meta Data');
      });
  }, []);

  React.useEffect(() => {
    const fetchData = async () => {
      fetchMetaData();
      setPublishableKey(await getStripePublishableKey());
    };
    fetchData();
  }, [fetchMetaData]);

  return (
    <ThemeProvider theme={theme}>
      {!!fetchRequestSuccess && publishableKey && (
        <BrandContext.Provider value={brandDetails}>
          <LanguageSelect />
          <Elements stripe={loadStripe(publishableKey)}>
            <Routes>
              {AppRoutes.map((route, index) => {
                const { element, ...rest } = route;
                return <Route key={index} {...rest} element={element} />;
              })}
            </Routes>
          </Elements>
        </BrandContext.Provider>
      )}
    </ThemeProvider>
  );
};

export default App;

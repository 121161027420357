import { FetchData } from './components/FetchData';
import { OrderSummary } from './pages/order-summary';
import { RepairBooking } from './pages/repair-booking';
import { Checkout } from './pages/checkout';
import { Error } from './pages/error';
import { SelectHome } from './pages/select-home/select-home';

const AppRoutes = [
  {
    path: '/',
    element: <SelectHome />,
  },
  {
    path: '/repair-booking',
    element: <RepairBooking />,
  },
  {
    path: '/order-summary',
    element: <OrderSummary />,
  },
  {
    path: '/fetch-data',
    element: <FetchData />,
  },
  {
    path: '/checkout',
    element: <Checkout />,
  },
  {
    path: '/error',
    element: <Error />,
  },
];

export default AppRoutes;

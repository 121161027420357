import brandApi from './brandApiConfig';
import bringApi from './bringApiConfig';
import stripeApi from './stripeApiConfig';
import orderBookingApi from './orderBookingApiConfig';
import { ShipmentBookingDataModel } from '../types/logistics/bring.model';
import { RepairerStoresData, PaymentIntentResult } from '../types/order/order.model';
import { ResponseModel } from '../types/common/response.model';
import { LogisticsOptionRequestModel, LogisticsOptionsResponseModel, PostnordLogisticsOptionRequestModel } from '../types/logistics/logisticsOptions.model';
import repairableBrandApi from './repairableBrandApiConfig';
import discountApi from './discountApiConfig';
import postnordApi from './postnordApiConfig';
import {  PostnordBookingDataModel, PostNordProductResponseModel } from '../types/logistics/postnord';

// Brands List

export const getBrandsList = async () => {
  const headers: any = {
    'Access-Control-Allow-Credentials': true,
    Authorization: 'Basic ' + window.btoa('repairable_admin:p@55w0rd'),
  };
  let response = await brandApi.get('Outlet/GetBrandsList', { headers: headers });
  return response?.data;
};

export const validateTenantKey = async (tenantUrl: string, localBrand: string = '0') => {
  const headers: any = {
    'Access-Control-Allow-Credentials': true,
  };
  let response = await brandApi.get<ResponseModel>(
    `/Brand/ValidateTenantKey?url=${tenantUrl}&localBrand=${localBrand}`,
    { headers: headers },
  );

  // console.log('Tenant Key Resp:', response);
  return response?.data.data;
};

// ------------- Sas URL -------------

export const getReadSasUrl = async (apiKey: string, containerName: string, blobName: string) => {
  const headers: any = {
    'Access-Control-Allow-Credentials': true,
    Authorization: apiKey,
  };
  let response = await brandApi.get(`/Brand/GetReadSasUrl?containerName=${containerName}&blobName=${blobName}`, {
    headers: headers,
  });
  return response?.data;
};

export const getUploadSasUrl = async (apiKey: string, containerName: string, blobName: string) => {
  const headers: any = {
    'Access-Control-Allow-Credentials': true,
    Authorization: apiKey,
  };
  let response = await brandApi.get(`/Brand/GetUploadSasUrl?containerName=${containerName}&blobName=${blobName}`, {
    headers: headers,
  });
  return response?.data;
};

// ---------------------------------

export const getMetaData = async (apiKey: string) => {
  const headers: any = {
    'Access-Control-Allow-Credentials': true,
    Authorization: apiKey,
  };
  let response = await brandApi.get(`/Brand/GetBrandDetail`, {
    headers: headers,
  });
  return response;
};

export const getDamageDetails = async (
  apiKey: string,
  customerCity: string,
  customerStreetNumber: string,
  customerStreetName: string,
  customerPostalCode: string,
  countryCode: string,
  categoryId: number,
  subCategoryId: number,
) => {
  const headers: any = {
    'Access-Control-Allow-Credentials': true,
    Authorization: apiKey,
  };
  let response = await brandApi.get(
    `/Brand/GetDamageDetail?customerCity=${customerCity}&customerStreetNumber=${customerStreetNumber}&customerStreetName=${customerStreetName}&customerPostalCode=${customerPostalCode}&countryCode=${countryCode}&categoryId=${categoryId}&subCategoryId=${subCategoryId}`,
    { headers: headers },
  );
  return response?.data ?? {};
};

export const createOrder = async (apiKey: string, args: any) => {
  const headers: any = {
    'Access-Control-Allow-Credentials': true,
    Authorization: apiKey,
  };
  let response = await orderBookingApi.post(`/BookOrder/BookOrder`, args, { headers: headers });
  return response?.data ?? {};
};

export const getRepairerStudioDetails = async (apiKey: string, orderId: number) => {
  const headers: any = {
    'Access-Control-Allow-Credentials': true,
    Authorization: apiKey,
  };
  let response = await orderBookingApi.get<RepairerStoresData>(`/BookOrder/GetRepairerStoreData?orderID=${orderId}`, {
    headers: headers,
  });
  return response?.data ?? {};
};

export const getCheckoutSession = async (args: any) => {
  let response = await stripeApi.post(`create-checkout-session`, args);
  return response?.data ?? {};
};

export const getStripePublishableKey = async () => {
  let response = await stripeApi.get(`/CustomFlow/GetStripePublishableKey`);
  return response.data ?? '';
};

export const createStripePaymentIntent = async (apiKey: string, args: any) => {
  const headers: any = {
    'Access-Control-Allow-Credentials': true,
    Authorization: apiKey,
  };
  let response = await stripeApi.post<PaymentIntentResult>(`/CustomFlow/CreatePaymentIntent`, args, {
    headers: headers,
  });
  return response.data;
};

export const getPaymentIntentSecret = async (apiKey: string, orderId: number) => {
  const headers: any = {
    'Access-Control-Allow-Credentials': true,
    Authorization: apiKey,
  };
  let response = await stripeApi.get<ResponseModel>(`/CustomFlow/GetPaymentIntentSecret?orderId=${orderId}`, {
    headers: headers,
  });
  return response.data;
};

export const updateOrderPaymentStatus = async (apiKey: string, orderId: number) => {
  const headers: any = {
    'Access-Control-Allow-Credentials': true,
    Authorization: apiKey,
  };
  let response = await orderBookingApi.get(`/BookOrder/UpdateRepiarPaymentStatus?orderId=${orderId}`, {
    headers: headers,
  });
  return response?.data ?? {};
};

export const getOrderDetails = async (apiKey: string, orderId: number) => {
  const headers: any = {
    'Access-Control-Allow-Credentials': true,
    Authorization: apiKey,
  };
  let response = await orderBookingApi.get(`/BookOrder/GetRepairDetail?orderID=${orderId}`, { headers: headers });
  return response?.data ?? {};
};
export const paymentGateway = async () => {
  let response = await stripeApi.post(`/create-checkout-session`);
  return response?.data ?? {};
};

export const getDropLocations = async (
  apiKey: string,
  countryCode: string,
  postalCode: string,
  streetName: string,
  streetNumber?: string,
) => {
  const headers: any = {
    'Access-Control-Allow-Credentials': true,
    Authorization: apiKey,
  };
  let response = await bringApi.get(
    `/BringApi/GetPickupPointsByAddress?countryCode=${countryCode}&postalCode=${postalCode}&searchForText=${
      streetName + ' ' + streetNumber
    }`,
    { headers: headers },
  );
  return response?.data ?? {};
};

export const getRepairerStudioData = async (apiKey: string, repairerStudioName: string) => {
  const headers: any = {
    'Access-Control-Allow-Credentials': true,
    Authorization: apiKey,
  };
  let response = await orderBookingApi.get(`/BookOrder/GetRepairDetail?orderID=${repairerStudioName}`, {
    headers: headers,
  });
  return response?.data ?? {};
};

export const createShipment = async (args: ShipmentBookingDataModel) => {
  let response = await bringApi.post(`/BringAPI/CreateBookingForShipment`, args);
  return response?.data ?? {};
};

export const createPostnordShipment = async (args: PostnordBookingDataModel) => {
  let response = await postnordApi.post(`/BookShipment`, args);
  return response?.data ?? {};
}

// SHIPPING COST:

export const getShippingCost = async (apiKey: string, countryCode: string, postalCode: string, repairerId: number) => {
  const headers: any = {
    'Access-Control-Allow-Credentials': true,
    Authorization: apiKey,
  };
  let response = await bringApi.get(
    `/BringAPI/GetLogisticsPrice?fromCountryCode=${countryCode}&fromPostalCode=${postalCode}&repairerId=${repairerId}`,
    { headers: headers },
  );
  return response?.data ?? {};
};

export const getLogisticsOptions = async (apiKey: string, args: LogisticsOptionRequestModel) => {
  const headers: any = {
    'Access-Control-Allow-Credentials': true,
    Authorization: apiKey,
  };
  let response = await bringApi.post<LogisticsOptionsResponseModel>(`/BringAPI/GetLogisticsOptions`, args, {
    headers: headers,
  });
  return response?.data ?? {};
};

export const getPostNordLogisticsOptions = async (apiKey: string, args: PostnordLogisticsOptionRequestModel) => {
  const headers: any = {
    'Access-Control-Allow-Credentials': true,
    Authorization: apiKey,
  };

  let response = await postnordApi.post<Array<PostNordProductResponseModel>>(`/GetLogisticsOptions`, args, {
    headers: headers,
  });
  return response?.data ?? {};
};



//
// DISCOUNT CODE
//

export const getDiscountCodeDetails = async (discountCode: string, brandID: number) => {
  let response = await discountApi.get(
    `/BrandDiscountCode/GetDiscountCodeDetail?discountCode=${discountCode}&brandID=${brandID}`,
  );
  return response?.data;
};

//
// SHOPIFY INTEGRATION
//

export const getLineItemsByOrderID = async (apiKey: string, orderId: number) => {
  const headers: any = {
    'Access-Control-Allow-Credentials': true,
    Authorization: apiKey,
  };
  let response = await brandApi.get(`/Shopify/GetOrderLineItems?orderId=${orderId}`, { headers: headers });
  return response?.data;
};

export const getRepairDetailsForShopifyOrderId = async (
  apiKey: string,
  orderId: number,
  selectedProductId: number,
  selectedVariantId: number,
) => {
  const headers: any = {
    'Access-Control-Allow-Credentials': true,
    Authorization: apiKey,
  };
  let response = await brandApi.get(
    `/Shopify/GetRepairDetailsForShopifyOrderId?orderId=${orderId}&selectedProductId=${selectedProductId}&selectedVariantId=${selectedVariantId}`,
    { headers: headers },
  );
  return response?.data;
};
